export default {
  page: {
    title: {
      base: 'Autopay Payment Gateway',
      start: 'Izbor načina plaćanja',
      visadata: 'Visa Mobile',
      continue: 'Nastavi - Odabir načina plaćanja',
      wait: 'Očekivanje',
      confirmation: 'Potvrda uplate',
      thankYou: 'Hvala na uplati',
      error: 'Pogreška stranice',
      errorNotFound: 'Stranica nije pronađena',
      secureBack: 'Učitavanje stranice',
      maintenance: 'Web stranica u tijeku održavanja',
      regulationsApproval: 'Propisi i politika privatnosti',
      toTransferData: 'podaci o prijenosu',
      sessionTimeout: 'Stranica nakon završetka sesije',
      paywayError: 'Kanal plaćanja nije dostupan'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Povećaj font',
      decreaseFontSize: 'Smanji font',
      changeContrast: 'Promjeni kontrast',
      changeLanguage: 'Promjena jezika stranice',
      skipToContent: 'Preskoči na sadržaj',
      skipToContentLabel: 'Prijeđi na glavni sadržaj',
      logoTitle: 'Logo stranice'
    },
    sessionTimer: {
      timeOut: 'Sesija je istekla',
      sessionEnd: 'Kraj sesije za:',
      label: 'Produži vrijeme sesije',
      button: 'Produži'
    }
  },
  footer: {
    text1: 'Imate li dodatna pitanja na temu z on-line plaćanja ili drugim uslugama? <a title="Otvara se u novom prozoru" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" target="_blank" rel="noreferrer">Idite na stranicu pomoći</a>',
    text2: {
      part1: 'Administrator osobnih podataka je Autopay S.A.',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. Davanje osobnih podataka je dobrovoljno, ali nužno za izvršenje podnesenog zahtjeva. Pravna osnova, svrha, razdoblje obrade osobnih podataka i prava Korisnika, kao i druge važne informacije o načelima obrade podataka su detaljno navedeni u <a href="https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenti/politika-privatnosti.pdf" target="_blank" title="Idite na stranicu s dokumentom Pravila privatnosti (128 KB, PDF)" rel="noreferrer">politika privatnosti Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Prikaži dodatne informacije',
    linkShowMoreText: 'Čitaj više',
    linkShowLessTitle: 'Sakrij dodatne informacije',
    linkShowLessText: 'Čitaj manje'
  },
  paywall: {
    header: 'Odaberite način plaćanja',
    info: {
      paywayNotAvailable: 'Jako nam je žao, ali izgleda tako odabrani način plaćanja trenutno nije moguć na vašem uređaju.',
      applePayNotAvailable: 'Jako nam je žao, ali izgleda tako, da plaćanje putem Apple Pay, u ovom trenutku nije moguće na vašem uređaju.',
      googlePayNotAvailable: 'Jako nam je žao, ali izgleda tako da plaćanje putem Google Pay nije moguće u ovom trenutku na vašem uređaju.'
    },
    paywayGroup: {
      inactiveMessage: 'Plaćanje trenutno nije dostupno',
      notice: {
        novelty: 'NOVOST',
        promotion: 'POSEBNA PONUDA',
        recommended: 'PREPORUČENO',
        mastercard: 'NAJSIGURNIJE PLAĆANJE NA INTERNETU',
        mastercardC2P: 'NAJSIGURNIJE PLAĆANJE NA INTERNETU',
        blik: 'BLIKOMANIA - Registriraj se i osvoji dijamante!',
        visaMobile: 'Plaćajte glatko i sigurno'
      },
      bank: {
        many: 'banaka',
        one: 'banka',
        two: 'banke'
      },
      wallet: {
        many: 'novčanici',
        one: 'novčanik',
        two: 'novčanici'
      },
      otp: {
        many: 'načine',
        one: 'put',
        two: 'načine'
      },
      blikPayLater: {
        info: 'Saznaj više'
      }
    },
    paywayList: {
      label: 'Skupina vrsta plaćanja',
      linkSelectOther: 'Odaberite drugi'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Imam račun u drugoj banci'
    },
    paywaySwitch: {
      methodShortSingle: 'Jednokratna',
      methodLongSingle: 'Jednokratna plaćanja',
      methodShortAuto: 'Ciklična',
      methodLongAuto: 'Ponavljajuća plaćanja'
    },
    form: {
      payway: {
        label: 'Odaberite način plaćanja'
      },
      card: {
        label: 'Unesite podatke kartice',
        iframe: {
          title: 'Obrazac s podacima kreditne kartice je za dopunu'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Obrazac s brojem telefona za popunjavanje'
        },
        status: {
          pending: {
            statusText: 'Čeka se potvrda',
            header: 'Potvrdite uplatu u aplikaciji',
            descriptionText1: 'Plaćanje ćete potvrditi u aplikaciji Visa Mobile ili u aplikaciji za bankarstvo s aktivnom uslugom Visa Mobile',
            descriptionText2: 'Nakon potvrde transakcije u aplikaciji pričekajte rezultat plaćanja',
            descriptionText3: 'Otkazivanje transakcije u tijeku',
            cancelButton: 'Otkaži plaćanje'
          },
          success: {
            statusText: 'Hvala na kupnji!',
            descriptionText: 'Plaćanje uspješno'
          },
          error: {
            statusText: 'Provjerite imate li dovoljno sredstava na platnoj kartici koja se koristi za Visa Mobile plaćanja.',
            descriptionText: 'Plaćanje odbijeno'
          }
        },
        apps: 'Visa Mobile aplikacije za plaćanje',
        help: 'Trebate li pomoć?',
        info: 'Saznaj više',
        secure: 'Sigurno plaćanje zahvaljujući SSL enkripciji',
        modal2: {
          header: 'Za potvrdu plaćanja otvorite Visa Mobile aplikaciju ili aplikaciju banke u kojoj imate aktivnu Visa Mobile uslugu',
          listText1: 'imate instaliranu aplikaciju Visa Mobile ili uslugu Visa Mobile aktivnu u mobilnoj aplikaciji vaše banke',
          listText2: 'imate uključene obavijesti u aplikaciji Visa Mobile ili u aplikaciji za bankarstvo s aktivnom uslugom Visa Mobile',
          listText3: 'broj koji ste dali je važeći: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Također provjerite da...'
        }
      },
      click2pay: {
        loaderText: 'Tražimo vašu adresu e-pošte<br>u <strong>Klikni za plaćanje</strong>',
        saveCard: {
          text1: 'Spremite svoju karticu',
          text2: 'Vaši će podaci biti sigurno spremljeni u <strong>Mastercard Click to Pay</strong>',
          link: 'Saznaj više'
        },
        email: {
          labelForClick2Pay: 'E-mail adresa za obavijesti o plaćanju'
        },
        cardSelect: {
          selected: 'Odabrano',
          select: 'Odaberi',
          payOther: 'Unesite podatke o kartici',
          back: 'Natrag'
        },
        cardSelected: {
          header: 'Koristite karticu spremljenu u Click to Pay',
          date: 'vrijedi do',
          buttonChange: 'Promijeni'
        },
        codeForm: {
          description: 'Pronašli smo vašu adresu e-pošte u Click to Pay.',
          codeSentEmail: 'Unesite jednokratni kod koji smo poslali na {email} da se prijavite na svoj račun.',
          codeSentPhone: 'Unesite jednokratni SMS kod koji smo poslali na {phone} da se prijavite na svoj račun.',
          codeLabel: 'Unesite kod',
          codePlaceholder: 'unesite kod',
          codeLabelFlow: 'Kôd',
          codeSendLabel: 'Pošalji novi kod',
          codeSendAriaLabel: 'putem',
          codeSendAriaLabel2: 'ili',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-pošta',
          trustedLabel: 'Dodajte ovaj uređaj na svoj popis pouzdanih<br><span>Sljedeći put ćemo prepoznati ovaj uređaj i platit ćete bez prijave</span>',
          buttonSubmit: 'Dalje',
          buttonChangeCard: 'Unesite podatke o kartici'
        },
        info: {
          description: 'Plaćanje karticom je kriptirano i sigurno. Transakcija će biti autorizirana korištenjem 3DSecure na web stranici banke.'
        },
        loginForm: {
          emailLabel: 'Unesite svoju adresu e-pošte za prijavu na Click to Pay',
          emailPlaceholder: 'adresa e-pošte',
          emailLabelFlow: 'E-pošta',
          buttonSubmit: 'Prijava',
          buttonBack: 'Natrag'
        },
        newUserForm: {
          header: 'Spremi karticu u Click to Pay',
          description: 'Potrebno nam je još nekoliko detalja da bismo spremili vašu karticu.',
          emailLabel: 'Unesite adresu e-pošte',
          emailPlaceholder: 'adresa e-pošte',
          emailLabelFlow: 'E-pošta',
          countryLabel: 'Država',
          firstnameLabel: 'Ime',
          firstnamePlaceholder: 'ime',
          firstnameLabelFlow: 'Ime',
          lastnameLabel: 'Prezime',
          lastnamePlaceholder: 'prezime',
          lastnameLabelFlow: 'Prezime',
          phoneLabel: 'Telefonski broj',
          phonePlaceholder: 'broj telefona',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kod',
          countryCodeLabelFlow: 'Kod',
          trustedLabel: 'Dodajte ovaj uređaj na svoj popis pouzdanih<br><span>Sljedeći put ćemo prepoznati ovaj uređaj i platit ćete bez prijave</span>',
          TermsLabel: 'Nastavkom prihvaćate <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Uvjete korištenja</a> i razumijete da će vaši podaci biti obrađeni u skladu s <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank ">Politika privatnosti</a>Mastercard.',
          c2pInfo: 'Klikni za plaćanje koristit će ove informacije za vašu provjeru i slanje kontrolnih kodova na ovaj broj. Moguće su naknade za poruke/podatke.',
          buttonSubmit: 'Uštedi i plati',
          buttonBack: 'Plati bez spremanja kartice'
        },
        profile: {
          header: 'Prijavite se za Click to Pay',
          buttonChangeEmail: 'Promijeni adresu e-pošte'
        },
        modal: {
          title: 'Click to Pay - informacije',
          header: 'Plaćajte brzo i sigurno, bez prepisivanja podataka o kartici u novom standardu Click to Pay',
          text1: 'Kada plaćate karticom, potražite logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> i platite svojom platnom karticom brzo i povoljno.',
          text2: 'Click To Pay novi je standard kartičnog plaćanja koji podržavaju Visa i Mastercard.',
          text3: 'Možete platiti karticom na vašem Click to Pay računu u trgovinama u Poljskoj i inozemstvu, bez kopiranja podataka kartice.',
          text4: 'Podaci se sigurno spremaju u Visa ili Mastercard. Sve informacije su potpuno šifrirane.',
          text5: 'Klikni za plaćanje će prilikom naknadnih plaćanja prepoznati Vašu e-mail adresu, tako da odmah možete platiti svojim spremljenim karticama.',
          text6: 'Dodatno potvrđujete uplate u svojoj banci, što jamči punu sigurnost.',
          processError: 'Došlo je do pogreške prilikom izvršenja plaćanja Click to Pay. Izvršite standardno plaćanje karticom.'
        }
      },
      email: {
        label: 'Unesite e-mail adresu',
        flowLabel: 'E-mail',
        tip: 'Trebamo vašu e-mail adresu, kako bi vam mogli poslati informacije koje se odnose na status plaćanja.'
      },
      terms: {
        header: 'Suglasnosti i izjave',
        selectAll: 'Odaberi sve',
        fieldNotRequired: 'Polje nije obavezno',
        partnerMarketingTextLess: 'Želite li primati e-mail, SMS ili telefonske obavijesti o najboljim ponudama, promocijama, natjecanjima i drugim zanimljivim događanjima u organizaciji nas ili naših partnera?',
        partnerMarketingTextMore: 'Obećavamo da nećemo slati neželjenu poštu! Ako vam dosade naše vijesti, svoju suglasnost možete povući u bilo kojem trenutku.',
        tradeMarketingTextLess: 'Želite li da vaš e-mail ili telefonski broj možemo proslijediti našim pouzdanim partnerima kako bi vam mogli prezentirati zanimljive informacije i ponude?',
        tradeMarketingTextMore: 'Jamčimo vam da su grupa naših partnera priznate tvrtke koje, baš kao i mi, ne vole neželjenu poštu, a svoju suglasnost možete povući u bilo kojem trenutku.'
      },
      termsLabelDefault: 'Pročitao sam i prihvaćam <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Pobierz plik z regulaminem usługi" rel="noreferrer">Uvjete </a>usluga plaćanja i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Pobierz plik z polityką prywatności Autopay S.A.">Pravila privatnosti </a>. Želim da usluga bude pružena odmah, a u slučaju odustajanja od ugovora, znam da neću dobiti povrat novca za obavljene usluge, na moj zahtjev, do odustajanja od ugovora.',
      termsReccuring: 'Ako želite izvršiti plaćanje, prihvatite <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Pobierz plik z regulaminem usługi" rel="noreferrer">Propise </a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Pobierz plik z polityką prywatności Autopay S.A.">Politiku privatnosti</a> Autopay S.A., pristajete na trenutnu implementaciju usluge plaćanja i pristajete na terećenje moje platne kartice',
      termsReccuringMore1: 'Slažem se da će usluga biti pružena odmah, a u slučaju odustajanja od ugovora, znam da neću dobiti povrat novca za pružene usluge na moj zahtjev do odustajanja od ugovora.',
      termsReccuringMore2: 'Slažem se s cikličkim punjenjem od strane Autopay S.A. moju platnu karticu kako bih dovršio transakciju prema  {receiverName}. Načela periodične naplate kartice propisana su <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Pobierz plik z regulaminem usługi" rel="noreferrer">Pravilnikom </a> o pružanju platnih usluga. Izjavljujem da sam upoznat s datumima, učestalošću i pravilima za određivanje iznosa koji se terete na mojoj platnoj kartici, kao i načinom opoziva ove suglasnosti, a koje sam odredio s {receiverName}. Kontaktirat ću {receiverName} ako budem imao bilo kakvih pitanja o periodičnom terećenju moje platne kartice.',
      mwfClauseLess: 'Ako želite izvršiti plaćanje, prihvatite odredbe i uvjete <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Uvjeti pružanja usluge" rel="noreferrer">Pravila</a> i <a href="https://bluemedia.pl/storage/app/media/Bluemedia_pl/Dokumenti/politika-privatnost.pdf" target="_blank" title="Politika privatnosti Autopay S.A.">politike privatnosti Autopay S.A.</a>, pristanak za pokretanje transakcije s vašom bankom putem Autopay S.A. te dostavljanje pojedinosti o transakciji primatelju plaćanja i traženje trenutnog izvršenja usluge plaćanja.',
      mwfClauseMore: 'Želim da usluga bude pružena odmah i znam da u slučaju odustajanja od ugovora neću dobiti povrat novca.',
      mwfClauseARIA: 'Ako želite izvršiti uplatu, prihvatite propise i politiku privatnosti Autopay S.A., pristajete na pokretanje transakcija u vašoj banci putem Autopay S.A. te dostavljanje pojedinosti o transakciji primatelju plaćanja i traženje trenutnog izvršenja platne usluge. Želim da usluga bude pružena odmah i znam da u slučaju odustajanja od ugovora neću dobiti povrat novca. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Preuzmite datoteku s uvjetima usluge</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Preuzmite datoteku iz politike privatnosti Autopay S.A.</a>',
      paymentInfo: 'Nalog za plaćanje se dostavlja putem Autopay S.A. sa sjedištem u Sopotu i bit će realiziran u skladu s uvjetima koje je odredila vaša banka. Nakon odabira  banke autorizirat ćete plaćanje.',
      changePaywayLink1: 'Još uvijek možete',
      changePaywayLink2: 'promjeniti način plaćanja',
      linkMore: 'VIŠE',
      linkShowMoreTitle: 'Pogledajte dodatne informacije',
      linkLess: 'MANJE',
      linkShowLessTitle: 'Sakrij dodatne informacije',
      submitButton: {
        visaMobile: 'Platite do',
        fastTransfer: 'Generiraj podatke za prijenos',
        mwf: 'Prihvaćam i plaćam',
        pis: 'Prihvaćam i počinjem s plaćanjem',
        auto: 'Naručite periodično plaćanje',
        autoPayment: 'Plaćanje naloga',
        other: 'Plati',
        orange: 'Potvrdi',
        c2pNext: 'Dalje',
        c2pAccept: 'Prihvaćam',
        c2pSaveAndPay: 'Uštedi i plati'
      }
    },
    frData: {
      pageTitle: 'Detaljni podaci za prijenos',
      header: 'Izvršite prijenos pomoću ponuđenog dane',
      nrbLabel: 'Račun primatelja',
      nameLabel: 'Naziv primatelja',
      addressLabel: 'Adrea primatelja',
      titleLabel: 'Naslov prijenosa',
      amountLabel: 'Iznos prijenosa',
      timeLabel: 'Izvrši transakciju do',
      linkCopy: 'Kopiraj',
      linkGetPdf: 'Preuzmi PDF s podacima',
      linkGetPdfTitle: 'Otvara se u novom prozoru',
      linkGoToBank: 'Prijavite se u bankubanku',
      info: 'Prijavite se na svoj bankovni račun. Kopirajte podatke u prijenosu. Bitno je da podaci budu isti, ne mijenjajte iznos, valutu, naziv i slično.'
    },
    processing: {
      pageTitle: 'Stranica čekanja za obradu transakcije',
      loaderAnimation: 'Animacija prikazana tijekom čekanja',
      header: 'Pripremamo vašu stranicu za plaćanje.',
      description: 'Sačekajte trenutak...',
      info: {
        header: 'Upamtite!',
        description: 'Nakon završetka transakcije ne zatvarajte stranicu - ako to uradite vaši novci neće biti doznačeni primatelju.'
      },
      loaderText: 'Podaci se prenose.<br>To će nam uzeti maksimalno 30 sekundi.'
    }
  },
  thankYou: {
    pageTitle: 'Podaci o rezultatu transakcije',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Natrag na stranicu'
    },
    negative: {
      header: 'Plaćanje nije realizirano zbog nedostatka autorizacije transakcije.',
      description: 'Ako i dalje želite koristiti ponudu određenog prodavača ili pružatelja usluge - vratite se na njihovu web stranicu, odaberite proizvod ili uslugu i platite metodom koju odaberete.'
    },
    pending: {
      header: 'Hvala vam za nalog za plaćanje putem pristupnika za plaćanje.',
      description: 'Status transakcije se provjerava. Kada se proces završi, na vašu e-mail adresu dobit ćete informaciju o statusu plaćanja.'
    },
    positive: {
      header: 'Hvala vam, vaša transakcija je realizirana.',
      description: 'Potvrdu ćemo poslati na e-mail adresu koju ste naveli.'
    }
  },
  regulationsApproval: {
    header: 'Prihvatite transakciju'
  },
  wait: {
    pageTitle: 'Stranica čekanja'
  },
  sessionTimeout: {
    pageTitle: 'Stranica nakon završetka sesije',
    header: 'Vrijeme dovršetka plaćanja je isteklo',
    description: 'Možete se vratiti na web stranicu partnera u bilo kojem trenutku, ponovno naručiti ili koristiti uslugu i platiti metodom po vašem izboru.'
  },
  paywayError: {
    pageTitle: 'Odabrani kanal plaćanja nije dostupan',
    header: 'Nažalost, odabrani način plaćanja trenutno nije dostupan.',
    description: 'Vratite se na partnersku stranicu i pokušajte ponovno kasnije.'
  },
  loader: {
    defaultAnimation: 'Animacija se prikazuje dok se stranica učitava',
    slider: 'Klizač s promjenom tekstova',
    text1: 'U Autopay težimo neutralnosti ugljika. Također to radiš koristeći naše usluge.',
    text2: 'U Autopay idemo biciklima na posao ograničavanjem emisije stakleničkih plinova u atmosferu. To možete učiniti i korištenjem naših usluga. ',
    text3: 'U Autopay napaja nas zelena energija. Vas također kada koristite naše usluge.',
    text4: 'U Autopay smanjujemo upotrebu papira. Također to možete učiniti i korištenjem naših usluga.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Pojedinosi',
    headerAriaLabel: 'Pojedinosti o transakciji',
    amountLabel: 'Dospjeli iznos',
    receiverLabel: 'Primatelj',
    orderLabel: 'Broj narudžbe',
    amountOryginalCurrencyLabel: 'Iznos u {currency}',
    currencyRateLabel: 'Tečaj',
    feeLabel: 'Naknada za transakciju',
    customerNumberLabel: 'Broj kupca',
    invoiceNumberLabel: 'Broj fakture',
    accountNumberLabel: 'Broj računa',
    paywayLabel: 'Način plaćanja',
    paywayName: 'Online bankovni prijenos',
    linkBack: 'Natrag u trgovinu',
    linkBackTitle: 'Napusti transakciju i vrati se u trgovinu',
    linkMore: 'više',
    linkLess: 'manje',
    toggleBtnUnfoldAriaLabel: 'Proširi pojedinosti o narudžbi',
    toggleBtnFoldAriaLabel: 'Sažmi pojedinosti o narudžbi'
  },
  error: {
    pageTitle: 'Stranica s pogreškom',
    linkBackUrlSet: 'Natrag na stranicu partnera',
    linkBackUrlNotSet: 'Idite na stranicu pomoć',
    sessionTimeout: {
      title: 'Vrijeme dovršetka plaćanja je isteklo',
      message: 'Možete se vratiti na web stranicu partnera u bilo kojem trenutku, ponovno naručiti ili koristiti uslugu i platiti metodom po vašem izboru.'
    },
    notFoundError: {
      pageTitle: 'Stranica pogreške 404 - stranica nije pronađena',
      title: 'Čini se da ste otišli predaleko i niste mogli pronaći stranicu koju želite posjetiti.',
      message: 'Mogući razlozi: stranica ne postoji ili je obrisana, servisni radovi su u tijeku, adresa stranice je netočna ili je došlo je do tehničke greške.'
    },
    generalError: {
      title: 'Ispričavamo se',
      message: 'Trenutačno ne možemo realizirati vaš prijenos.'
    },
    errFieldNotFound: {
      title: 'Ispričavamo se',
      message: ''
    },
    errBadClientSource: {
      title: 'Ispričavamo se',
      message: ''
    },
    nrParametersError: {
      title: 'Ispričavamo se',
      message: 'Trenutačno ne možemo realizirati vaš prijenos.'
    },
    transactionOutdated: {
      title: 'Vrijeme dovršetka plaćanja je isteklo.',
      message: 'Dajte ponovo nalog.'
    },
    linkValidityTimeOutdated: {
      title: 'Vrijeme dovršetka plaćanja je isteklo.',
      message: 'Dajte ponovo nalog.'
    },
    transactionValidityTimeOutdated: {
      title: 'Vrijeme dovršetka plaćanja je isteklo.',
      message: 'Dajte ponovo nalog.'
    },
    multiplyTransaction: {
      title: 'Ispričavamo se',
      message: 'Transakcija već postoji i čeka na plaćanje.'
    },
    transactionCanceled: {
      title: 'Ispričavamo se',
      message1: 'Transakcija je otkazana.',
      message2: 'Dajte ponovo nalog.'
    },
    multiplyPaidTransaction: {
      title: 'Ispričavamo se',
      message: 'Transakcija je već plaćena.'
    },
    transactionReject: {
      title: 'Ispričavamo se',
      message1: 'Ups, nešto je pošlo po zlu.',
      message2: 'Već uklanjamo zapreku...'
    },
    bankDisabled: {
      title: 'Ispričavamo se',
      message: 'Odabrana banka trenutno nije dostupna.'
    },
    bankTemporaryMaintenance: {
      title: 'Ispričavamo se',
      message: 'Trenutačno ne možemo realizirati vaš prijenos.'
    },
    insufficientStartAmount: {
      title: 'Ispričavamo se',
      message: 'Ograničenje iznosa prijenosa je premašeno'
    },
    startAmountOutOfRange: {
      title: 'Ispričavamo se',
      message: 'Ograničenje iznosa prijenosa je premašeno.'
    },
    nonAccountedLimitExceeded: {
      title: 'Ispričavamo se',
      message: 'Plaćanje se ne može izvršiti jer je prodavatelj dosegao mjesečni limit prodaje.'
    }
  },
  confirmation: {
    pageTitle: 'Informacije o tijeku plaćanja',
    linkBack: 'Vraćam se na stranicu',
    linkBackTimeout: 'Povratak na web stranicu partnera za ',
    incorrectAmount: {
      header: 'Pogrešan iznos',
      description: 'Nismo prenijeli vašu uplatu primatelju jer je iznos bio netočan.',
      description2: 'Kliknite gumb za povratak na stranicu i ponovite je. Upamtite da ne mijenjate podatke.'
    },
    multiplePaid: {
      header: 'Dvostruki depozit',
      description: 'Primatelj je primio vašu uplatu dva puta. Ne brinite - dobit ćete povrat novca.',
      description2: 'Kontaktirajte primatelja i dostavite mu potvrdu plaćanja.'
    },
    negative: {
      header: 'Kraj vremena',
      description: 'Nismo prenijeli vašu uplatu primatelju jer je vrijeme za potvrdu isteklo.',
      description2: 'Kliknite gumb za povratak na stranicu i ponovite je.'
    },
    notFound: {
      header: 'Nema plaćanja',
      description: 'Došlo je do greške prilikom obrade Vaše uplate. Ali ništa nije izgubljeno.',
      description2: 'Kliknite gumb za povratak na stranicu i ponovite je.'
    },
    outdated: {
      header: 'Kraj vremena',
      description: 'Nismo prenijeli vašu uplatu primatelju.',
      description2: 'Kliknite gumb za povratak na stranicu i ponovite je.'
    },
    outdatedPaidNotRefunded: {
      header: 'Kraj vremena',
      description: 'Nismo prenijeli vašu uplatu primatelju. Ako je novac povučen s vašeg računa, ne brinite, povrat ćete dobiti u roku od nekoliko dana.',
      description2: 'Kliknite gumb za povratak na stranicu i ponovite je.'
    },
    outdatedPaidRefunded: {
      header: 'Kraj vremena',
      description: 'Nismo prenijeli vašu uplatu primatelju. Ako je novac skinut s vašeg računa, ne brinite, već smo ga vratili.',
      description2: 'Kliknite gumb za povratak na stranicu i ponovite je.'
    },
    pending: {
      header: 'U tijeku',
      description: 'Obrađujemo vašu uplatu. Obavijestit ćemo vas kada stigne do primatelja.'
    },
    positive: {
      header: 'Plaćeno',
      visaVideo: 'Reklamni video reproduciran nakon uspješne transakcije',
      description: 'Prebacili smo vaša sredstva primatelju.'
    }
  },
  messages: {
    accessForbidden: 'Nedostatak pristupa',
    internalServerError: 'Dogodila se neočekivana pogreška. Molimo pokušajte ponovo kasnije.',
    validators: {
      required: 'Polje je obavezno',
      pattern: 'Unesite važeću vrijednost',
      emailRequired: 'E-mail adresa je obavezna.',
      emailNotValid: 'Vaša e-mail adresa sadrži pogrešku. Molimo unesite ispravnu adresu e-pošte',
      emailNotRecognized: 'Navedena adresa e-pošte nije registrirana',
      emailIdentityLookup: 'Neuspješna provjera adrese e-pošte',
      emailLocked: 'Navedena adresa e-pošte privremeno je blokirana u sustavu Mastercard',
      paywayRequired: 'Odaberite način plaćanja',
      creditCardRequired: 'Ispunite sva polja',
      phoneRequired: 'Unesite važeći broj telefona',
      regulationRequired: 'Privatite pravila',
      requiredConsent: 'Potreban pristanak',
      codeNotValid: 'Uneseni kod nije važeći',
      codeAccountLocked: 'Račun je privremeno blokiran u sustavu Mastercard'
    },
    validatorsAria: {
      required: 'Pažnja. Polje obrasca nije popunjeno. Obavezno polje.',
      pattern: 'Pažnja. Polje sadrži nevažeću vrijednost.',
      emailRequired: 'Pažnja. Polje e-pošte nije popunjeno. Unesite valjanu adresu e-pošte.',
      emailNotValid: 'Pažnja. Vaša e-mail adresa sadrži pogrešku. Unesite valjanu adresu e-pošte.',
      emailNotRecognized: 'Pažnja. Navedena adresa e-pošte nije registrirana.',
      emailIdentityLookup: 'Pažnja. Navedenu adresu e-pošte nije bilo moguće potvrditi',
      emailLocked: 'Pažnja. Navedena adresa e-pošte privremeno je blokirana u sustavu Mastercard.',
      paywayRequired: 'Pažnja. Plaćanje nije odabrano. Odaberite način plačanja.',
      creditCardRequired: 'Pažnja. Ispunite sva polja s podacima o kreditnoj kartici.',
      phoneRequired: 'Pažnja. Polje telefonskog broja sadrži pogrešku. Unesite važeći broj telefona.',
      codeNotValid: 'Pažnja. Navedeni kod nije valjan.',
      codeAccountLocked: 'Pažnja. Račun je privremeno blokiran u sustavu Mastercard.'
    },
    success: {
      copy: 'Kopirano!',
      codeSent: 'Kod został wysłany!'
    },
    errors: {
      getPdf: 'Preuzimanje PDF datoteke nije uspjelo!',
      copy: 'Kopiranje nije uspjelo!',
      thankYouUpdate: 'Nije uspjelo spremanje adrese e-pošte!',
      groupInactive: 'Trenutačno je nemoguće izvršiti prijenos pomoću grupe {groupName}. Odaberite drugi način plaćanja.',
      paywayInactive: 'U ovom trenutku, zbog tehničkih razloga, nije moguće izvršiti prijenos iz odabrane banke. Odaberite drugu banku ili način plaćanja.',
      googlePayInactive: 'Nažalost plaćanje putem Google Pay nije dostupno na vašem uređaju.',
      applePayInactive: 'Plaćanje putem Apple Pay dostupno je za osobe koje koriste Safari preglednik i uređaje s operativnim sustavom iOS.',
      codeNotSent: 'Ponovno slanje koda nije uspjelo!',
      codeCounterExceeded: 'Premašen je broj dopuštenih pokušaja. Unesite podatke o kartici ručno ili upotrijebite drugi način plaćanja.'
    }
  },
  modal: {
    closeButton: 'Zatvoriti',
    confirmButton: 'Shvaćam'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Ponavljajuće plaćanje',
        description: 'Unesite podatke o kartici koju ćete koristiti za plaćanje. Automatska plaćanja će se kasnije preuzeti s vaše kartice.',
        labelInitWithRefund: 'Provjera plaćanja',
        labelInitWithRefundOrange: 'Dodajte platnu karticu',
        labelInitWithPayment: 'Plaćanje karticom',
        descriptionInitWithRefund: 'Unesite podatke o kartici koju želite potvrditi za buduća plaćanja. U sljedećem koraku ćemo naplatiti PLN s dane kartice za provjeru točnosti podataka. Nakon provjere vratit ćemo iznos.',
        descriptionInitWithPayment: 'Unesite podatke o kartici koju ćete koristiti za izvršenje transakcije i istovremeno je potvrdite za buduća plaćanja.'
      },
      blik: {
        label: 'BLIK',
        description: 'Plati odmah unosom BLIK koda, bez prijave i registracije'
      },
      card: {
        label: 'Plaćanje karticom',
        description: 'Platite svojom platnom karticom'
      },
      pbl: {
        label: 'On-line bankovni prijenos',
        description: 'Odaberite banku iz koje želite izvršiti uplatu',
        descriptionVerify: 'On-line provjera identiteta',
        descriptionServiceVerify: 'Odaberite banku koja će vam omogućiti provjeru on-line usluge'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Visa Mobile plaćanje'
      },
      wallet: {
        label: 'Virtualni novčanik',
        description: 'Plati bez prijave u internetsko bankarstvo'
      },
      installments: {
        label: 'Plati u ratama',
        description: 'Platite svoje kupnje na povoljne rate'
      },
      alior: {
        descriptionInfo1: 'Plaćajte svoje kupnje na prikladne rate',
        descriptionInfo2: '(provjeri detalje)',
        aliorModal: {
          text1: 'Ovisno o postavkama web stranice na kojoj kupujete, proizvode možete kupovati na rate u sljedećim varijantama:',
          text2: '<ul><li>10 rata 0%</li><li>20 rata 0%</li><li>od 3 do 48 rata s mjesečnim troškom od 1% (prema prikazanom reprezentativnom primjeru za određenu transakciju)</li></ul>'
        }
      },
      otp: {
        label: 'Plati kasnije',
        description: 'Kupi sada plati kasnije',
        descriptionInfo1Mwf: 'Plaćanje računa naknadno - do 45 dana odjednom ili u više jednakih rata',
        descriptionInfo1Ecommerce: 'Plaćanje kasnije - do 45 dana odjednom ili u više jednakih rata',
        descriptionInfo2Mwf: 'Informacije o kreditnom posredniku i trošku',
        descriptionInfo2Ecommerce: 'Podaci o troškovima',
        labelPayka: 'Payka',
        descriptionPayka: 'Plaćanje računa naknadno - jednokratno do 30 dana ili u 4 jednake rate <a href="" id="paykaShowInModal">Informacije o kreditnom posredniku i trošku</a>',
        descriptionBlikPayLater: 'Usluga je dostupna u Millenium banci i Velobanci',
        paykaModalMwf: {
          header1: 'Informacije o kreditnom posredniku',
          text1: 'Autopay S.A. sa sjedištem u Sopotu, djeluje kao kreditni posrednik Paytree S.A. sa sjedištem u Gdanjsku (Zajmodavac), ovlašten za predstavljanje kreditne ponude klijentima i preusmjeravanje na web stranicu Zajmodavca, uključujući zahtjev za kredit. Detalji ponude na: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Podaci o troškovima',
          text2: 'Plaćanje kasnije - do 45 dana odjednom ili u više jednakih rata. Podaci o troškovima za reprezentativni primjer: Otplata unutar 45 dana APR 0%; Obročna otplata APR 86,83%. Detalji ponude na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Podaci o troškovima',
          text2: 'Plaćanje kasnije - do 45 dana odjednom ili u više jednakih rata. Podaci o troškovima za reprezentativni primjer: Otplata unutar 45 dana APR 0%; Obročna otplata APR 86,83%. Detalji ponude na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Kupite sada, platite u roku od 30 dana',
          header2: 'BLIK kod',
          text2: 'Unesite BLIK kod i nudimo vam ograničenje kupnje do 4000 PLN',
          header3: 'Kupujete proizvode i plaćate ih kasnije',
          text3: 'Imate vremena provjeriti odgovara li vam sve',
          header4: 'Plaćate u roku od 30 dana bez naknade',
          text4: 'Kupljenu robu također možete vratiti u roku koji odredi trgovina'
        }
      },
      companyPayments: {
        label: 'Plaćanja tvrtke',
        description: 'Pomaknite datum plaćanja za kupnje tvrtke za 30 dana'
      },
      transfer: {
        label: 'Podaci za bankovni prijenos',
        description: 'Izvrši prijenos koristeći dane podatke'
      },
      other: {
        label: 'Ostale',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Ispričavamo se. Trenutačno ne možemo izvršiti vaš prijenos. Odabrana banka trenutno nije dostupna.',
        blockMultipleTransactions: 'Ispričavamo se. Transakcija već postoji i čeka na plaćanje.',
        blockPaidTransactions: 'Ispričavamo se. Transakcija je već plaćena. Ne možete ponovno izvršiti plaćanje.',
        canceledTransactions: 'Transakcija koju pokušavate platiti je otkazana i nije dostupna. Molimo započnite novu transakciju.',
        declined: 'Autorizacija odbijena.',
        generalError: 'Postoji privremeni problem s vezom. Trenutačno ne možemo izvršiti vaš prijenos. Provjerite ponovno kasnije.',
        insufficientStartAmount: 'Ispričavamo se. Ograničenje iznosa prijenosa je premašeno.',
        internalServerError: 'Upsss.. nije uspjelo :(. Radimo na rješavanju problema. Molimo pokušajte ponovo kasnije.',
        nonAccountedLimitExceeded: 'Plaćanje se ne može izvršiti jer je prodavatelj dosegao mjesečni limit prodaje.',
        outdatedError: 'Vrijeme za dovršetak plaćanja je isteklo.',
        paid: 'Transakcija je uspješno završena.',
        paywayLoaded: 'Stranica za brzi prijenos je učitana',
        paywaylistLoaded: 'Stranica za odabir načina plaćanja je učitana'
      }
    }
  },
  maintenance: {
    text1: 'U tijeku je novogodišnje pospremanje platnog pristupnika Autopay, stoga je u ovom trenutku nemoguće izvršiti plaćanje. Ispričavamo se zbog neugodnosti.',
    text2: 'Pristupnik će raditi već 16 siječnja od 10:00.',
    text3: 'Hvala na razumijevanju.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Unesite podatke o vlasniku računa',
      flowLabel: 'Vlasnik računa',
      placeholder: 'Unesite podatke o vlasniku računa'
    },
    nip: {
      label: 'Unesite svoj porezni identifikacijski broj',
      flowLabel: 'NIP',
      placeholder: 'Unesite svoj porezni identifikacijski broj'
    }
  }
}
